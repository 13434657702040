import React, { FC, useEffect, useRef } from 'react';
import { Box, Button, HStack, Text, useMediaQuery } from '@chakra-ui/react';
import { toArrayProps } from '@/components/CMS/CMSComponents';
import { CMSComponentPredictionGame } from '@/types/api/ge-strapi/cms-page/predictionGame';
import { useSmarticoContext } from '@/components/Smartico/SmarticoScript';
import { useAuth } from '@/context/Auth';
import { useLoginModal } from '@/context/LoginModal';
import useTranslation from 'next-translate/useTranslation';

const CMSPredictionGame: FC<CMSComponentPredictionGame> = ({
  id,
  matchXId,
  theme,
  height,
  align,
  width,
  userNotLoggedMessage,
  labelLoginButton
}) => {
  const { t } = useTranslation();
  const { isLogged, isLoginFetching } = useAuth();
  const uniqueId = `script-match-x-${matchXId}-${id}`;
  const boxContentRef = useRef<HTMLDivElement | null>(null);
  const [isScreenMobile] = useMediaQuery('(max-width: 600px)');
  const { isSmarticoInitialized, setSmarticoInitialized } =
    useSmarticoContext();
  const { onOpen: onOpenLogin } = useLoginModal();

  useEffect(() => {
    if (isLogged) {
      // on logging success ensure to reset the smarticoInitialized flag
      setSmarticoInitialized(false);
    }
  }, [isLogged, setSmarticoInitialized]);

  useEffect(() => {
    if (isLogged && !isLoginFetching && isSmarticoInitialized) {
      window._smartico?.miniGame(matchXId, {
        iframe: uniqueId,
        zoom: 1,
        height: 'auto',
        theme: theme
      });
    }
  }, [
    isLogged,
    isLoginFetching,
    isSmarticoInitialized,
    matchXId,
    theme,
    uniqueId
  ]);

  return (
    <HStack
      justifyContent={align ? align.value : 'center'}
      data-testid="cms-prediction-game-container"
    >
      <Box
        width={toArrayProps(width, '%')}
        data-testid="cms-prediction-game-content"
        ref={boxContentRef}
      >
        {isLogged ? (
          <>
            <Box
              id={uniqueId}
              as="iframe"
              data-testid={uniqueId}
              style={{
                width: '100%',
                minHeight: isScreenMobile
                  ? '100vh'
                  : `${height ? height + 'px' : '100vh'}`
              }}
            ></Box>
          </>
        ) : !!userNotLoggedMessage || !!labelLoginButton ? (
          <Box display={'flex'} flexDirection={'column'} gap={4}>
            {!!userNotLoggedMessage ? (
              <Text
                align={'center'}
                dangerouslySetInnerHTML={{ __html: userNotLoggedMessage }}
              />
            ) : null}

            {!!labelLoginButton ? (
              <Box textAlign={'center'}>
                <Button
                  isLoading={isLoginFetching}
                  onClick={onOpenLogin}
                  bg="buttonPrimary"
                >
                  {labelLoginButton}
                </Button>
              </Box>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </HStack>
  );
};

export default CMSPredictionGame;
