/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import { useAppSettings } from '@/context/AppSettings';
import { useAuth } from '@/context/Auth';
import { useLoginModal } from '@/context/LoginModal';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { HTTP } from '@/components';
import { NavBar } from '@/components/Navbar';
import { ExpendableMenu } from '@/components/Menu';
import {
  ApiGameLaunchRequest,
  ApiGameLaunchResponse
} from '@/components/Button/PlayNowButton';
import {
  Box,
  Button,
  Container,
  Progress,
  Text,
  useMediaQuery,
  useToast,
  VisuallyHidden,
  VStack
} from '@chakra-ui/react';
import { ExtraAppTypes } from './_app';
import { getLicenseByHost, getPathByLicense } from '@/utils/multiDomains';
import SEO from '@/components/SEO';
import { UserLicencesType } from '@/services/handleAuth';
import { useWallet } from '@/context/Wallet';
import { AxiosResponse } from 'axios';
import { getFallbackLanguage } from '@/helpers/lang';
import { GetServerSideProps } from 'next';
import { useSportCategoryTabs } from '@/hooks/useSportCategoryTabs';
import { getSeoTagsConfig, SeoTags } from '@/helpers/seo';
import getT from 'next-translate/getT';
import { PortalHeader } from '@/components/Portal';
import { getCMSPage } from '@/services/cmsPage';
import { renderCMSContentComponents } from '@/components/CMS/CMSComponents';
import { CMSPageApiData } from '@/types/api/ge-strapi/cms-page';

export const containerId = 'altenarsportsbook';
const languageCodes: Record<string, string> = {
  en: 'en-GB',
  fr: 'fr-FR',
  nl: 'nl-NL',
  zh: 'zh-CN',
  ar: 'ar-AE',
  el: 'el-GR',
  ro: 'ro-RO',
  tr: 'tr-TR',
  it: 'it-IT'
};

const SportsPage = ({
  extra,
  cmsContent,
  seoTagsConfig
}: {
  extra: ExtraAppTypes;
  cmsContent: CMSPageApiData;
  seoTagsConfig: Partial<Record<SeoTags, string>>;
}) => {
  const [isScriptReady, setIsScriptReady] = useState<boolean>(false);
  const { lang, t } = useTranslation();
  const { isLogged, isLoginFetching, getLicences } = useAuth();
  const config = useAppSettings();
  const router = useRouter();
  const { onOpen } = useLoginModal();
  const toast = useToast();
  const [isScreenMobile] = useMediaQuery('(max-width: 600px)');
  const [isScreenTablet] = useMediaQuery(
    '(min-width: 601px) and (max-width: 992px)'
  );
  const { getBalance, balance: userWalletBalance } = useWallet();
  const isAltenarEnable = config?.features?.altenar?.ALTENAR_SPORTSBOOK;
  const gameId = config?.features?.altenar?.SPORTSBOOK_GAME_ID;
  const frontJsSrc = config?.features?.altenar?.SPORTSBOOK_FRONT_JS_SRC;
  const integration = config?.features?.altenar?.SPORTSBOOK_FRONT_INTEGRATION;
  const [token, setToken] = useState<string | undefined>();
  const [widgetInstance, setWidgetInstance] = useState<
    WidgetInstance | undefined
  >();
  const [isAltenarMounted, setIsAltenarMounted] = useState<boolean>(true);
  const [isGettingToken, setIsGettingToken] = useState<boolean>(true);
  const screenSizeType = isScreenMobile
    ? 'mobile'
    : isScreenTablet
    ? 'tablet'
    : 'desktop';
  const contentData = cmsContent.attributes;

  const { sportCategories, menuItems, menuItemsOptions } =
    useSportCategoryTabs();

  const getNewToken = async (): Promise<Token> => {
    const launchParams: ApiGameLaunchRequest = {
      gameId,
      lang,
      demo: false,
      screenSizeType,
      exitUrl: window?.location?.href
    };

    const {
      data: { launchUrl, error }
    } = await HTTP.post<ApiGameLaunchResponse>('game/launch', launchParams);

    if (error) throw new Error(error);
    if (!launchUrl) throw new Error('NO_LAUNCH_URL');

    const params = new URLSearchParams(launchUrl);
    const parsedToken = params.get('token');
    const parsedLanguage = params.get('lang');

    return {
      newToken: parsedToken || '',
      newLanguage: parsedLanguage || 'en-GB'
    };
  };
  const handleToken = async () => {
    // console.log('handleToken');

    try {
      const { newToken } = await getNewToken();
      setToken(newToken);
    } catch (err) {
      toast({
        title: 'error',
        description: (err as Error).message,
        status: 'error',
        duration: 4000,
        position: 'top-right',
        isClosable: true
      });
    }
  };
  const mountAltenar = () => {
    if (window.altenarWSDK) {
      const instance = window.altenarWSDK.init({
        culture: languageCodes[lang] || 'en-GB',
        integration,
        // numFormat: 'fr-FR',
        oddsFormat: 0,
        // themeName: 'themeName',
        token,
        dateFormat: {
          time: 'hh:mm',
          day: 'DD',
          date: 'DD/MM/YY',
          dateTime: 'DD/MM/YY \u2022 hh:mm',
          dayMonth: 'DD/MM',
          dayMonthTime: 'DD/MM \u2022 hh:mm'
        }
      });

      // Used to set the token if altenar is already mounted
      if (window.ASB) {
        window.altenarWSDK.set({
          token
        });
      }

      window.ASB = window.altenarWSDK.addSportsBook({
        props: {
          banners: [
            {
              id: 'top-center',
              url: `/sliderAltenar.html?lang=${getFallbackLanguage(
                lang
              )}&screenSizeType=${screenSizeType}`
            }
          ],
          onSignInButtonClick: () => {
            // console.log('onSignInButtonClick');
            onOpen();
          },
          onBetPlacement: (betIds) => {
            // console.log('onBetPlacement');
            getBalance?.();
          },
          onCashOut: () => {
            // console.log('onBetPlacement');
            getBalance?.();
          },
          culture: languageCodes[lang] || 'en-GB'
        },
        container: document.getElementById(containerId)
      });

      setWidgetInstance(instance);
      setIsAltenarMounted(true);
    }
  };

  useEffect(() => {
    if (!isAltenarEnable) router?.push(getPathByLicense(extra.license));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAltenarEnable]);

  useEffect(() => {
    if (isLoginFetching || !isAltenarEnable) return;

    if (isLogged) {
      getLicences?.().then((userLicences: UserLicencesType[]) => {
        if (
          userLicences &&
          !userLicences.some((x) => x.name === `${extra.license}+` && x.enabled)
        ) {
          toast({
            title: t('common:licenceNotAllowed', {
              name: `${extra.license}+`
            }),
            description: (
              <>
                <p>{t('common:toPlayThisGame')}</p>
                <Button
                  variant="outline"
                  style={{ marginTop: '1em' }}
                  onClick={() => router.push('/me/account/licences')}
                  data-testid="licenses-update-button"
                >
                  {t('common:updateMySettings')}
                </Button>
              </>
            ),
            status: 'warning',
            position: 'top',
            duration: null,
            isClosable: true
          });
          setIsGettingToken(false);
        } else {
          setIsGettingToken(true);
          handleToken().then(() => setIsGettingToken(false));
        }
      });
    } else {
      setIsGettingToken(true);
      if (widgetInstance) {
        window.altenarWSDK.set({
          token: ''
        });
      }
      setTimeout(() => setIsGettingToken(false), 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, isLoginFetching, isAltenarEnable, lang]);

  useEffect(() => {
    if (isLoginFetching || isGettingToken || !isScriptReady) return;
    mountAltenar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginFetching, isGettingToken, isScriptReady]);

  return (
    <>
      {isAltenarEnable && (
        <Script
          strategy="lazyOnload"
          src={frontJsSrc}
          onReady={() => setIsScriptReady(true)}
        />
      )}
      <VisuallyHidden>
        <Text as="h1">{t('seo:sportsPageH1')}</Text>
      </VisuallyHidden>
      <SEO
        title={seoTagsConfig.title ?? t('seo:sportsLiveTitle')}
        description={
          seoTagsConfig.description ?? t('seo:sportsLiveDescription')
        }
      />
      <PortalHeader>
        <NavBar basic />
      </PortalHeader>
      {contentData?.descriptionHeader ? (
        <Text
          as="h2"
          textStyle="h1"
          marginBottom={'1rem'}
          textAlign={'center'}
          width={'100%'}
        >
          {contentData.descriptionHeader}
        </Text>
      ) : null}

      {contentData?.descriptionLead ? (
        <Text
          as={'h2'}
          textStyle="h2"
          padding={'1rem 0'}
          textAlign={'center'}
          width={'100%'}
        >
          {contentData.descriptionLead}
        </Text>
      ) : null}
      <div className={screenSizeType === 'desktop' ? 'bg-global' : ''}>
        <Container maxW="full" paddingTop="8" minH={500} px={[0, 0, 8]}>
          <ExpendableMenu
            items={menuItems}
            options={menuItemsOptions}
            position={'relative'}
            zIndex={1}
            marginBottom={[8, 8, 10]}
          />

          <Box id={containerId} position={'relative'} zIndex={0} px={[4, 4, 0]}>
            <Progress isIndeterminate />
          </Box>

          <VStack
            as="main"
            spacing={{ base: '4rem', md: '6rem' }}
            marginTop="8rem"
            marginX="2rem"
            // maxWidth="58rem"
          >
            {renderCMSContentComponents(contentData?.content)}
          </VStack>
        </Container>
      </div>
    </>
  );
};

// SportsPage.getInitialProps = async () => {
//   return {};
// };

export const getServerSideProps: GetServerSideProps = async ({
  locale = 'en',
  req
}) => {
  const t = await getT(locale, 'seo');
  const {
    headers: { host }
  } = req;
  const license = host ? getLicenseByHost(host) || 'MAIN' : 'MAIN';
  const content = await getCMSPage({
    locale,
    license,
    apiUrl: process.env.STRAPIAPI,
    apiPath: `/sport-content`,
    populateKeys: [
      'bannerPositions',
      'domainName',
      'licences',
      'metaTags',
      'metaTags.image',
      'redirection',
      'content',
      'content.textAlign',
      'content.link',
      'content.align',
      'content.width',
      'content.children',
      'content.config',
      'content.config.sm',
      'content.config.md',
      'content.config.lg'
    ]
  });

  return {
    props: {
      cmsContent: content,
      seoTagsConfig: getSeoTagsConfig({
        license,
        title: content.attributes?.metaTags?.title ?? t('sportsLiveTitle'),
        description:
          content.attributes?.metaTags?.description ??
          t('sportsLiveDescription')
      })
    }
  };
};

export default SportsPage;

interface WidgetInstance {}

export interface SportCategoryResponse extends AxiosResponse {
  data: {
    data: SportCategory[];
    meta: {
      pagination: {
        page: number;
        pageSize: number;
        pageCount: number;
        total: number;
      };
    };
  };
}

export interface SportCategory {
  id: number;
  attributes: {
    name: string;
    redirectUrl: string | null;
    redirectTarget: string | null;
    customCssClass: string | null;
    order: number;
    children: SportCategoryChild[];
  };
}

export interface SportCategoryChild {
  id: number;
  name: string;
  redirectUrl: string;
  redirectTarget: string;
}

type Token = {
  newToken: string;
  newLanguage: string;
};
