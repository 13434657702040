import getConfig from 'next/config';
import { Provider } from '@/components/GamesCatalog/types';
import { getFallbackLanguage } from '@/helpers/lang';

const getBaseUrl = () => {
  const { serverRuntimeConfig } = getConfig();
  const gameServiceUrl = serverRuntimeConfig?.SERVICE_GAME_URL;

  return new URL(`${gameServiceUrl}/providers`);
};

const fetchProviders = async (
  url: string,
  locale: string,
  traceId?: string
) => {
  let providers: Provider[] = [];
  try {
    const result = await fetch(url, {
      headers: {
        'Accept-Language': getFallbackLanguage(locale),
        'X-GE-Trace-Id': traceId
          ? traceId
          : 'ge-oria-providers-' + new Date().getTime()
      }
    });
    const data: any = await result.json();

    if (data.error) throw new Error(data.error);

    providers = data?.['hydra:member'] || data;
  } catch (error) {
    console.error('fetchProviders: ERROR LOADING PROVIDERS', {
      error,
      url,
      locale
    });
  }
  return providers;
};

export const getProvidersById = async (
  providerIds: string | string[],
  locale: string
) => {
  providerIds = Array.isArray(providerIds) ? providerIds : [providerIds];
  const baseUrl = getBaseUrl();
  const providersQuery = providerIds
    .map((id) => 'publicId%5B%5D=' + id)
    .join('&');
  const url = `${baseUrl}?${providersQuery}`;

  return fetchProviders(url, locale);
};

export const getVisibleProviders = async (locale: string, traceId?: string) => {
  const baseUrl = getBaseUrl();
  const providersQuery = 'pagination=false&visible=true&order[name]=asc';
  const url = `${baseUrl}?${providersQuery}`;

  return fetchProviders(url, locale, traceId);
};
