import { CMSComponentAccordion } from './accordion';
import { CMSComponentCallToAction } from './callToAction';
import { CMSComponentDivider } from './divider';
import { CMSComponentGrid } from './grid';
import { CMSComponentImage } from './image';
import { CMSComponentTextContent } from './textContent';
import { CMSComponentTitle } from './title';
import { CMSComponentGameCatalog } from './gameCatalog';
import { CMSComponentForm } from './form';
import { CMSComponentScript } from './script';
import { CMSComponentPredictionGame } from './predictionGame';
import { CMSComponentAltenarEventCard } from './altenarEventCard';
import { CMSComponentAltenarEventDetails } from './altenarEventDetails';
import { CMSComponentAltenarEventsChampionships } from './altenarEventsChampionships';
import { CMSComponentAltenarOutrightEvents } from './altenarOutrightEvents';
import { CMSComponentAltenarOutrightsChampionships } from './altenarOutrightsChampionships';
import { CMSComponentAltenarLiveOdds } from './altenarLiveOdds';
import { CMSComponentAltenarBoostedOdds } from './altenarBoostedOdds';
import { CMSComponentAltenarLiveOddsDetails } from './altenarLiveOddsDetails';
import { CMSComponentAltenarHighlights } from './altenarHighlights';
import { CMSComponentAltenarOverviewDetails } from './altenarOverviewDetails';
import { CMSComponentAltenarPopularBets } from './altenarPopularBets';
import { CMSComponentAltenarScoreBoard } from './altenarScoreBoard';
import { CMSComponentAltenarUpcoming } from './altenarUpcoming';
import { License } from '../licenses';
import { Localization } from '../localizations';
import { SEO } from '../seo';

export * from './accordion';
export * from './callToAction';
export * from './divider';
export * from './grid';
export * from './image';
export * from './textContent';
export * from './title';
export * from './gameCatalog';
export * from './form';
export * from './altenar';

export type CMSPageApiData = {
  id: number;
  attributes: {
    createdAt: string; // "2024-01-09T16:03:47.801Z"
    updatedAt: string; // "2024-01-09T16:03:47.801Z"
    publishedAt: string; // "2024-01-09T16:03:47.801Z"
    locale: 'en' | 'fr' | 'nl';
    name?: string;
    descriptionLead?: string;
    descriptionHeader?: string;
    slug?: string;
    localizations?: { data: Localization[] };
    metaTags?: SEO;
    redirection?: {
      code: number;
      url: string;
    };
    userNotLoggedMessage?: string;
    labelLoginButton?: string;
    licences?: { data: License[] };
    bannerPositions?: {
      data: {
        id: number;
        attributes: {
          name: 'top' | 'bottom' | 'topsports';
          createdAt: string; // "2024-01-09T15:54:48.693Z"
          updatedAt: string; // "2024-01-09T15:54:48.693Z"
          publishedAt: string; // "2024-01-09T15:54:48.693Z"
        };
      }[];
    };
    domainName?: { data: License };
    content: CMSComponent[];
  };
};

export const enum CMSComponentRef {
  ACCORDION = 'cms.accordion',
  CTA = 'cms.cta',
  DIVIDER = 'cms.divider',
  GRID = 'cms.grid',
  IMAGE = 'cms.image',
  TEXT_CONTENT = 'cms.text-content',
  TITLE = 'cms.title',
  GAME_CATALOG = 'cms.game-catalog',
  ALTENAR_BET_SLIP = 'cms.altenar-bet-slip',
  ALTENAR_EVENT_CARD = 'cms.altenar-event-card',
  ALTENAR_EVENT_DETAILS = 'cms.altenar-event-details',
  ALTENAR_EVENTS_CHAMPIONSHIPS = 'cms.altenar-events-championships',
  ALTENAR_OUTRIGHT_EVENTS = 'cms.altenar-outright-events',
  ALTENAR_OUTRIGHTS_CHAMPIONSHIPS = 'cms.altenar-outrights-championships',
  ALTENAR_LIVE_ODDS = 'cms.altenar-live-odds',
  ALTENAR_LIVE_ODDS_DETAILS = 'cms.altenar-live-odds-details',
  ALTENAR_HIGHLIGHTS = 'cms.altenar-highlights',
  ALTENAR_OVERVIEW_DETAILS = 'cms.altenar-overview-details',
  ALTENAR_POPULAR_BETS = 'cms.altenar-popular-bets',
  ALTENAR_SCORE_BOARD = 'cms.altenar-score-board',
  ALTENAR_UPCOMING = 'cms.altenar-upcoming',
  ALTENAR_BOOSTED_ODDS = 'cms.altenar-boosted-odds',
  FORM = 'cms.form',
  SCRIPT = 'cms.script',
  PREDICTION_GAME = 'cms.prediction-game'
}

export type NumberPerScreen = {
  id: number;
  sm: number;
  md: number;
  lg: number;
};

export type SelectedAlign = {
  id: number;
  value: 'left' | 'center' | 'right';
};

export type SelectNumber = {
  id: number;
  value: number;
};

export type SelectNumberArray = SelectNumber[];

export type SelectStatsOrResultsArray = {
  id: number;
  value: 'Stats' | 'Results';
}[];

export type SelectedLink = {
  id: number;
  text: string;
  title?: string;
  url: string;
  target: '_self' | '_blank';
};

export type SelectedTarget = {
  id: number;
  value: '_self' | '_blank';
};

export type SelectedTextAlign = {
  id: number;
  value: 'left' | 'center' | 'right' | 'justify';
};

export interface CMSContentBaseComponent {
  id: number;
  __component: CMSComponentRef;
  tokens?: { [token: string]: Record<string, string | number | null> };
}

export type CMSComponent =
  | CMSComponentAccordion
  | CMSComponentCallToAction
  | CMSComponentDivider
  | CMSComponentGrid
  | CMSComponentImage
  | CMSComponentTextContent
  | CMSComponentTitle
  | CMSComponentGameCatalog
  | CMSComponentForm
  | CMSComponentScript
  | CMSComponentPredictionGame
  | CMSComponentAltenarEventCard
  | CMSComponentAltenarEventDetails
  | CMSComponentAltenarEventsChampionships
  | CMSComponentAltenarOutrightEvents
  | CMSComponentAltenarOutrightsChampionships
  | CMSComponentAltenarLiveOdds
  | CMSComponentAltenarLiveOddsDetails
  | CMSComponentAltenarHighlights
  | CMSComponentAltenarOverviewDetails
  | CMSComponentAltenarPopularBets
  | CMSComponentAltenarScoreBoard
  | CMSComponentAltenarUpcoming
  | CMSComponentAltenarBoostedOdds;
